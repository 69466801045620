<template>
  <div class="login">
    <div class="login2">
      <div class="auPageCenter">
        <div class="infos">
          <p class="title">{{ title }}</p>
          <p class="lv">{{ desc }}</p>
          <a class="a" href="/">{{ link }}</a>
        </div>
        <!-- 登录面板 -->
        <au-cart class="loginPage">
          <div class="head">
            <a
              href="javascript:;"
              @click="$router.push('/login')"
              class="title"
              :class="{ active: type == 1 }"
              >账户登录</a
            >
            <a
              href="javascript:;"
              @click="$router.push('/register')"
              class="title"
              :class="{ active: type == 2 }"
              >注册账户</a
            >
          </div>
          <slot></slot>
        </au-cart>
      </div>
    </div>
  </div>
</template>

<script>
import auCart from "@/components/auCart.vue";
export default {
  components: { auCart },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "土豆查",
    },
    desc: {
      type: String,
      default: "数据查询、流量分析、账户管理",
    },
    link: {
      type: String,
      default: "www.tudoucha.com",
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  background: linear-gradient(-57deg, #2611c9, #7f95ff);
  .login2 {
    background: url("../../../../assets/img/bg8.png") no-repeat center 192px;
    width: 100%;
    height: 100%;
  }
}
.head {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  .title {
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
    color: #333;
    font-weight: 600;
    border: none;
    letter-spacing: 2px;
    &.active {
      color: var(--au-color);
      position: relative;
      &::after {
        display: block;
        content: "";
        width: 18px;
        height: 2px;
        background: var(--au-color);
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -9px;
      }
    }
  }
}
.loginPage {
  width: 420px;
  height: calc(100% - 80px);
}
.login {
  height: calc(100vh - 310px);
  width: 100%;
  margin-top: -60px;
  padding-top: 60px;
  margin-bottom: 0 !important;
}
.infos {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  & > * {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  * {
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  .a {
    font-size: 24px;
    background-image: linear-gradient(to right, #00d7a2, #01feff);
    color: #fff;
    height: 40px;
    line-height: 40px;
    width: 320px;
    text-align: center;
    border-radius: 40px;
    letter-spacing: 2px;
  }
  .title {
    font-weight: bold;
    font-size: 52px;
    color: #fff;
    letter-spacing: 16px;
  }
  .lv {
    font-size: 34px;
    letter-spacing: 8px;
    color: rgb(190, 190, 190);
    background-image: -webkit-linear-gradient(left, #00d7a2, #01feff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  .tudoucha {
    width: 260px;
  }
}
.auPageCenter {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
</style>