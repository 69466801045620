<template>
  <module-login :type="2">
    <div class="body">
      <au-input
        v-model:value="username"
        :requisite="true"
        placeholder="请输入用户名"
        icon="el-icon-user"
      ></au-input>
      <au-input
        v-model:value="phone.value"
        :requisite="true"
        placeholder="请输入手机号"
        :matchs="phone.matchs"
        icon="el-icon-mobile"
      ></au-input>
      <au-input
        v-model:value="password.value"
        type="password"
        :requisite="true"
        placeholder="请输入密码"
        :matchs="password.matchs"
        icon="el-icon-lock"
      ></au-input>
      <au-input
        v-model:value="quePass"
        type="password"
        :requisite="true"
        placeholder="请确认密码"
        :matchs="quePasswordMatchs"
        icon="el-icon-lock"
      ></au-input>
      <div class="submit">
        <el-button class="submitBtn" type="primary" size="medium"
          >注册</el-button
        >
      </div>
    </div>
  </module-login>
</template>

<script>
import auInput from "./components/login/auInput.vue";
import ModuleLogin from "./components/login/moduleLogin.vue";
export default {
  components: { ModuleLogin, auInput },
  data() {
    return {
      isSavePassword: false,
      phone: {
        value: "",
        matchs: [
          {
            match: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
      },
      password: {
        value: "",
        matchs: [
          (val) => {
            if (val !== this.password.val) {
            }
          },
        ],
      },
      username:"",
      quePass:""
    };
  },
  computed: {
    quePasswordMatchs() {
      const pass=this.password.value;
      return  [
          (val) => {
            console.log(val,pass)
            if (val !== pass) {
              return "两次输入的密码不一致"
            }
          },
        ]
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 30px 15px;
  display: flex;
  flex-flow: column nowrap;
  &>*{
    margin-bottom: 8px;
  }
  p {
    color: var(--au-color-font-sub);
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    .a {
      font-size: 12px;
      position: relative;
      top: -2px;
    }
  }
  .info {
    position: relative;
    top: -10px;
  }
  .submit {
    padding: 15px 0 0;
    .submitBtn {
      width: 100%;
      padding: 14px;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
    }
  }
}
</style>