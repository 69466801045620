<template>
  <div class="from">
    <el-input
      size="medium"
      :placeholder="placeholder"
      v-model="input"
      :type="type"
      @blur="onBlur"
      :class="{err:!!info}"
    >
      <template #prefix>
        <i :class="'el-input__icon ' + icon"></i>
      </template>
    </el-input>
    <div v-if="info" class="error">{{ info }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: "",
      input: "",
    };
  },
  watch:{
    input(val){
      this.$emit("update:value",val)
    }
  },
  props: {
    type: {
      default: "text",
      type: String,
    },
    icon: {
      default: "el-icon-mobile",
      type: String,
    },
    placeholder: {
      default: "请输入内容",
      type: String,
    },
    value: {
      default: "",
      type: String,
    },
    requisite:{
        default:true,
        type:Boolean
    },
    matchs:{
        type:Array,
        default:[]
    }
  },
  methods: {
    onBlur() {
        //验证
        return this.verification()
        .then(x=>{
            this.info='';
        }).catch(err=>{
            this.info=err.message||err;
        })
    },
    //验证方法
    verification(){
        let val=this.input;
        let _this=this;
        return new Promise((resolve,reject)=>{
            //是否为必须的
            if(_this.requisite && !val){
                return reject(_this.placeholder)
            }
            //循环验证
            for (let i = 0; i < _this.matchs.length; i++) {
                const it = _this.matchs[i];
                if(typeof it ==="function"){
                  const info=it(val);
                  if(info){
                    return reject(info);
                  }
                }else if(it.match && !it.match.test(val)){
                  return reject(it.message)
                }
            }
            resolve();
        })
    }
  },
};
</script>

<style lang="scss" scoped>
$h:46px;
.from {
  height: $h+22px;
  .err::v-deep input{
    border: 1px solid var(--el-color-danger);
  }
  .error {
    color: var(--el-color-danger);
  }
  ::v-deep {
    .el-input--medium .el-input__icon {
      line-height: $h;
      font-size: 18px;
    }
    .el-input__inner {
      height: $h;
      line-height: $h;
    }
  }
}
</style>